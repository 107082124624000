import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {LoginStyled, LoginContentStyled, FormFieldContainer, HeadlineStyled, ButtonStyled} from './styles';

import {Input} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';

import {forgotPassword} from '../../../state/actions/userManagement';
import Text from '../../Elements/Text/Text';
import {forms} from '../../../assets/language/de';
import {withAsterisk} from '../../../utils/formHelpers';
import FormRow from '../../Elements/Form/FormRow/FormRow';
import Column from '../../Layout/Grid/Column';
import Section from '../../Layout/Section/Section';
import Row from '../../Layout/Grid/Row';

const {text, label, validationMessages, buttons} = forms.PasswordResetRequest;

const ResetSchema = Yup.object().shape({
	Email: Yup.string().email(validationMessages.email.ifEmailNotValid).required(validationMessages.email.ifEmpty),
});

const RequestReset = (props) => {
	const {user, handleReset} = props;

	useEffect(() => {
		if (user) {
			navigate('/');
		}
	});

	const handleSubmit = (data, {resetForm}) => {
		handleReset(data);
		resetForm(RequestReset.initialValues);
	};

	return (
		<>
			<LoginStyled className="ap-login">
				<LoginContentStyled>
					<HeadlineStyled>{text.headline}</HeadlineStyled>
					<Text content={text.intro} />

					<FormFieldContainer>
						<Formik
							initialValues={{
								Email: '',
							}}
							validationSchema={ResetSchema}
							onSubmit={handleSubmit}
							render={() => (
								<Form>
									<Section center>
										<FormRow>
											<Column md={6}>
												<Input label={withAsterisk(label.email)} name="Email" />
											</Column>
										</FormRow>
										<FormRow>
											<Column>
												<ButtonStyled text={buttons.submit} theme="candy" type="submit" />
											</Column>
										</FormRow>
									</Section>
								</Form>
							)}
						/>
					</FormFieldContainer>
				</LoginContentStyled>
			</LoginStyled>
			<Section>
				<Row>
					<Text
						content="Biogen-169318"
						styles={{color: 'rgb(170, 170, 170)', fontSize: '0.7em', margin: '2rem', marginLeft: '0.5rem'}}
					/>
				</Row>
			</Section>
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});
const mapDispatchToProps = (dispatch) => ({
	handleReset: (data) => dispatch(forgotPassword(data)),
});

RequestReset.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
	}),
	handleReset: PropTypes.func,
};

RequestReset.defaultProps = {
	user: null,
	handleReset: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestReset);
